// color
$dark: #1c1c1c !default;
$light: #fbf9f9 !default;
$lightgray: #f4f4f4 !default;
$cfg: #f5f5f5 !default;
$gray: #e1e1e1 !default;
$darkgray: #eaeaea !default;
$purple: #9966ff !default;
$lightpurple: #9966ff22 !default;
$red: #ff6565 !default;
$lightred: #fceaef !default;
$highlight: #c9e94d !default;

// button
$btn-padding-y: 7px !default;
$btn-padding-x: 22px !default;
$btn-line-height: 1.35em !default;

$btn-padding-y-sm: 4.5px !default;
$btn-padding-x-sm: 14.5px !default;
$btn-line-height-sm: 1.35 !default;

$btn-padding-y-lg: 12px !default;
$btn-padding-x-lg: 30px !default;
$btn-padding-y-lg-mobile: 8px !default;
$btn-padding-x-lg-mobile: 20px !default;
$btn-line-height-lg: 1.35 !default;
