@import '../../../styles/variable';

.guide {
  h4 {
    font-size: 1.2rem;
    font-weight: 600;
  }

  p {
    max-width: 800px;
    display: inline-block;

    img {
      max-width: 100%;
      height: auto;
      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }
  }
}
